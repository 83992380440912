import Menu from '../components/Menu';

export default {
    init() {
        // JavaScript to be fired on all pages
        console.log ('init common.js');

        this.initContactSection();
    },

    initContactSection () {
        const $input = $('.contact-form-group:not(.contact-form-group-checkbox) input, .contact-form-group:not(.contact-form-group-checkbox) textarea');

        $input.focus(function () {
            $(this).parents('.contact-form-group').addClass('contact-form-group-focus');
        });

        // Supprime la class si on perd le focus
        $input.blur(function () {
            if($(this).val().length <= 0){
                $(this).parents('.contact-form-group').removeClass('contact-form-group-focus');
            }
        });

        $('.btn-open-contact').on('click', (e) => {
            $(e.currentTarget).parents('.contact-wrapper').eq(0).addClass('open-contact-form');
        });

        $('.form-close').on('click', (e) => {
            $(e.currentTarget).parents('.contact-wrapper').eq(0).removeClass('open-contact-form');
        });
    },

    initTarteaucitron () {
        // console.log($('link[href*="tarteaucitronjs"]'));
        tarteaucitron.init({
            "privacyUrl": "/cgu/", /* Privacy policy url */

            "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
            "cookieName": "tarteaucitron", /* Cookie name */

            "orientation": "bottom", /* Banner position (top - bottom) */
            "showAlertSmall": true, /* Show the small banner on bottom right */
            "cookieslist": true, /* Show the cookie list */

            "adblocker": false, /* Show a Warning if an adblocker is detected */
            "DenyAllCta" : true, /* Show the deny all button */
            "AcceptAllCta": true, /* Show the accept all button when highPrivacy on */
            "highPrivacy": true, /* Disable auto consent */
            "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

            "removeCredit": true, /* Remove credit link */
            "moreInfoLink": true, /* Show more info link */
            "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */

            "readmoreLink": "/cgu/" /* Change the default readmore link */
        });

        tarteaucitron.services.GGA = {
            "key": "GGA",
            "type": "analytic",
            "name": "Google Analytics",
            "needConsent": true,
            "cookies": ['cookie', 'cookie2'],
            "readmoreLink": "", // If you want to change readmore link
            "js": function () {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'gga-accepte', cyb_GGA: 'true'
                });
            },
            "fallback": function () {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'gga-refus', cyb_GGA: 'false'
                });
            }
        };

        tarteaucitron.services.plezicustom = {
            "key": "plezicustom",
            "type": "api",
            "name": "Plezi",
            "uri": "https://www.plezi.co/fr/mentions-legales/",
            "needConsent": true,
            "cookies": ['visitor', 'visit', '_ga', '_gid'],
            "js": function () {
                "use strict";
                if (tarteaucitron.user.pleziTenant === undefined || tarteaucitron.user.pleziTw === undefined) {
                    return;
                }
                tarteaucitron.addScript('//app.plezi.co/scripts/ossleads_analytics.js?tenant='+tarteaucitron.user.pleziTenant+'&tw='+tarteaucitron.user.pleziTw);
            }
        };

        (tarteaucitron.job = tarteaucitron.job || []).push('GGA');
        (tarteaucitron.job = tarteaucitron.job || []).push('pixelfacebook');
        (tarteaucitron.job = tarteaucitron.job || []).push('googleadwordsconversion');
        (tarteaucitron.job = tarteaucitron.job || []).push('googleadwordsremarketing');
        tarteaucitron.user.pleziTenant = '60489b8354067a0c13d70c04';
        tarteaucitron.user.pleziTw = '6058503ef42087765970ba34';
        (tarteaucitron.job = tarteaucitron.job || []).push('plezicustom');

        /*
         * Cookie modal management
         */
        // !! ne pas supprimer cette setinterval
        var checkInitTarteAuCitronJs = setInterval(() => {
            // cssBigAlertAuCitronJs = $('#tarteaucitronAlertBig').css('display');
            // console.log($('#tarteaucitronAlertBig').length);
            if($('#tarteaucitronAlertBig').length > 0){

                if ( $('link[href*="tarteaucitron"]').length > 0 ) {
                    $('link[href*="tarteaucitron"]').remove();
                }

                /*if (tarteaucitron.cookie.read().indexOf('true') === -1 && tarteaucitron.cookie.read().indexOf('false') === -1 ){
                    // console.log('test');
                    $('#modal_rgpd').fadeIn();
                    $('body').addClass('rgbd-open');
                } else {
                    $('#tarteaucitronIcon').fadeIn();
                }

                $('#tarteaucitronClosePanel').click(function() {
                    if (tarteaucitron.cookie.read().indexOf('true') > 0 || tarteaucitron.cookie.read().indexOf('false') > 0 ) {
                        $('#modal_rgpd').fadeOut();
                        $('.tarteaucitronIconBottomRight').css('display', 'block');
                        $('body').removeClass('rgbd-open');
                    } else {
                        $('#modal_rgpd').fadeIn();
                    }
                });*/

                clearInterval(checkInitTarteAuCitronJs);
            }
        }, 500);





        var tarteaucitronCtas = document.getElementsByClassName('openTarteaucitron');
        if( tarteaucitronCtas.length > 0 ) {
            for(var i= 0; i<tarteaucitronCtas.length; i++) {
                tarteaucitronCtas[i].onclick = function(e) {
                    e.preventDefault();
                    tarteaucitron.userInterface.openPanel();
                };
            }
        }

        var tarteaucitronCtasAcceptAll = document.getElementsByClassName('tarteaucitronAllAllowed');
        if( tarteaucitronCtasAcceptAll.length > 0 ) {
            for(var i= 0; i<tarteaucitronCtasAcceptAll.length; i++) {
                tarteaucitronCtasAcceptAll[i].onclick = function(e) {
                    e.preventDefault();
                    tarteaucitron.userInterface.respondAll(true);
                    $('#modal_rgpd').fadeOut();
                    $('body').removeClass('rgbd-open');
                };
            }
        }

        var tarteaucitronCtasDenyAll = document.getElementsByClassName('tarteaucitronAllDenied');
        if( tarteaucitronCtasDenyAll.length > 0 ) {
            for(var i= 0; i<tarteaucitronCtasDenyAll.length; i++) {
                tarteaucitronCtasDenyAll[i].onclick = function(e) {
                    e.preventDefault();
                    tarteaucitron.userInterface.respondAll(false);
                    $('#modal_rgpd').fadeOut();
                    $('body').removeClass('rgbd-open');
                };
            }
        }

        // console.log(tarteaucitron.cookie.read());
    },

    finalize() {
        console.log ('finalize common.js')
        let menu = new Menu();
        menu.init();

        $('.filter-select-form').on('change', function(e){
            $('.filter-select-form').submit()
        });

        this.initTarteaucitron ();
    },
};

window.disableEmptyInputs = function (form) {
    var controls = form.elements;
    for (var i=0, iLen=controls.length; i<iLen; i++) {
        if(controls[i].name != "s" || controls[i].name != "motCle" ){
            controls[i].disabled = controls[i].value == '';
        }
    }
}
